@import url(./vars.css);
@import url(./onboard.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nountown';
  src: url('/fonts/nountown.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  cursor: url('/cursor/ka-ching-cursor-default.cur') 3 3, auto !important;
}

button,
[role='button'],
a:-webkit-any-link,
.cursor-pointer,
.MuiButtonBase-root,
.MuiMenuItem-root,
.MuiSelect-select {
  cursor: url('/cursor/ka-ching-cursor-pointer.cur') 3 3, pointer !important;
}

button,
[role='button'] {
  @apply disabled:opacity-50;
}

.pixel-card {
  @apply relative transform transition-transform flex flex-col overflow-visible;
}

/* Black border created by clip-path */
.pixel-card::before {
  content: '';
  position: absolute;
  inset: -5px;
  background: black; /* This will be the color of the border */
  clip-path: polygon(
    /* Top-left corner */ 0 15px,
    5px 15px,
    5px 10px,
    10px 10px,
    10px 5px,
    15px 5px,
    15px 0,

    /* Top-right corner */ calc(100% - 15px) 0,
    calc(100% - 15px) 5px,
    calc(100% - 10px) 5px,
    calc(100% - 10px) 10px,
    calc(100% - 5px) 10px,
    calc(100% - 5px) 15px,
    100% 15px,

    /* Bottom-right corner */ 100% calc(100% - 15px),
    calc(100% - 5px) calc(100% - 15px),
    calc(100% - 5px) calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px),
    calc(100% - 10px) calc(100% - 5px),
    calc(100% - 15px) calc(100% - 5px),
    calc(100% - 15px) 100%,

    /* Bottom-left corner */ 15px 100%,
    15px calc(100% - 5px),
    10px calc(100% - 5px),
    10px calc(100% - 10px),
    5px calc(100% - 10px),
    5px calc(100% - 15px),
    0 calc(100% - 15px),
    /* Close the shape */ 0 15px
  );
  z-index: -2;
}

/* White inner area */
.pixel-card::after {
  content: '';
  position: absolute;
  inset: 0.1px; /* Slightly smaller than the black border */
  background: white;
  clip-path: polygon(
    /* Top-left corner */ 0 13px,
    3px 13px,
    3px 8px,
    8px 8px,
    8px 3px,
    13px 3px,
    13px 0,

    /* Top-right corner */ calc(100% - 13px) 0,
    calc(100% - 13px) 3px,
    calc(100% - 8px) 3px,
    calc(100% - 8px) 8px,
    calc(100% - 3px) 8px,
    calc(100% - 3px) 13px,
    100% 13px,

    /* Bottom-right corner */ 100% calc(100% - 13px),
    calc(100% - 3px) calc(100% - 13px),
    calc(100% - 3px) calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) calc(100% - 3px),
    calc(100% - 13px) calc(100% - 3px),
    calc(100% - 13px) 100%,

    /* Bottom-left corner */ 13px 100%,
    13px calc(100% - 3px),
    8px calc(100% - 3px),
    8px calc(100% - 8px),
    3px calc(100% - 8px),
    3px calc(100% - 13px),
    0 calc(100% - 13px),
    /* Close the shape */ 0 13px
  );
  z-index: -2;
}

/* Modified content structure */
.pixel-card > div:first-child {
  position: relative;
  z-index: 1;
  flex: 2; /* Takes up 2/3 of the space */
}

/* Bottom section with background */
.pixel-card > div[class*='bg-[#FFFDEA]'] {
  position: relative;
  z-index: 1;
  flex: 1;
  clip-path: polygon(
    3px 0,
    calc(100% - 3px) 0,
    calc(100% - 3px) calc(100% - 18px),
    calc(100% - 8px) calc(100% - 18px),
    calc(100% - 8px) calc(100% - 13px),
    calc(100% - 13px) calc(100% - 13px),
    calc(100% - 13px) calc(100% - 8px),
    calc(100% - 18px) calc(100% - 8px),
    calc(100% - 18px) calc(100% - 3px),
    18px calc(100% - 3px),
    18px calc(100% - 8px),
    13px calc(100% - 8px),
    13px calc(100% - 13px),
    8px calc(100% - 13px),
    8px calc(100% - 18px),
    3px calc(100% - 18px)
  );
}

/* Optional: Add padding to ensure content doesn't overlap with the corners */
.pixel-card > div[class*='bg-[#FFFDEA]'] > div {
  padding: 1rem;
}

.pixel-btn {
  position: relative;
  background: #ffc110;
  color: #000;
  padding: 8px 16px;
  border: none;
  font-weight: bold;
  min-width: 120px;
  min-height: 40px;
  clip-path: polygon(
    /* Top-left corner */ 0 4px,
    2px 4px,
    2px 2px,
    4px 2px,
    4px 0,
    /* Top-right corner */ calc(100% - 4px) 0,
    calc(100% - 4px) 2px,
    calc(100% - 2px) 2px,
    calc(100% - 2px) 4px,
    100% 4px,
    /* Bottom-right corner */ 100% calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) 100%,
    /* Bottom-left corner */ 4px 100%,
    4px calc(100% - 2px),
    2px calc(100% - 2px),
    2px calc(100% - 4px),
    0 calc(100% - 4px)
  );
  @apply transform transition-transform  hover:scale-[1.02];
}

.pixel-btn::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: 3px;
  bottom: 3px;
  background: #fff0be;
  z-index: -1;
  clip-path: polygon(
    /* Top-left corner */ 0 4px,
    2px 4px,
    2px 2px,
    4px 2px,
    4px 0,
    /* Top-right corner */ calc(100% - 4px) 0,
    calc(100% - 4px) 2px,
    calc(100% - 2px) 2px,
    calc(100% - 2px) 4px,
    100% 4px,
    /* Bottom-right corner */ 100% calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) 100%,
    /* Bottom-left corner */ 4px 100%,
    4px calc(100% - 2px),
    2px calc(100% - 2px),
    2px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.pixel-white-btn {
  position: relative;
  background: #ffc110;
  color: #000;
  font-weight: bold;
  min-width: 120px;
  min-height: 40px;
  clip-path: polygon(
    /* Top-left corner */ 0 4px,
    2px 4px,
    2px 2px,
    4px 2px,
    4px 0,
    /* Top-right corner */ calc(100% - 4px) 0,
    calc(100% - 4px) 2px,
    calc(100% - 2px) 2px,
    calc(100% - 2px) 4px,
    100% 4px,
    /* Bottom-right corner */ 100% calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) 100%,
    /* Bottom-left corner */ 4px 100%,
    4px calc(100% - 2px),
    2px calc(100% - 2px),
    2px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

/* White inner area */
.pixel-white-btn::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: 3px;
  z-index: -1;
  bottom: 3px;
  background: white;
  clip-path: polygon(
    /* Top-left corner */ 0 4px,
    2px 4px,
    2px 2px,
    4px 2px,
    4px 0,
    /* Top-right corner */ calc(100% - 4px) 0,
    calc(100% - 4px) 2px,
    calc(100% - 2px) 2px,
    calc(100% - 2px) 4px,
    100% 4px,
    /* Bottom-right corner */ 100% calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) 100%,
    /* Bottom-left corner */ 4px 100%,
    4px calc(100% - 2px),
    2px calc(100% - 2px),
    2px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.pixelRedBtn {
  position: relative;
  background: #ae3208;
  color: #fff;
  padding: 8px 16px;
  border: none;
  font-weight: bold;
  min-width: 120px;
  min-height: 40px;
  clip-path: polygon(
    /* Top-left corner */ 0 4px,
    2px 4px,
    2px 2px,
    4px 2px,
    4px 0,
    /* Top-right corner */ calc(100% - 4px) 0,
    calc(100% - 4px) 2px,
    calc(100% - 2px) 2px,
    calc(100% - 2px) 4px,
    100% 4px,
    /* Bottom-right corner */ 100% calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) 100%,
    /* Bottom-left corner */ 4px 100%,
    4px calc(100% - 2px),
    2px calc(100% - 2px),
    2px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

.pixelRedBtn::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: 3px;
  bottom: 3px;
  background: #bd2d24;
  z-index: -1;
  clip-path: polygon(
    /* Top-left corner */ 0 4px,
    2px 4px,
    2px 2px,
    4px 2px,
    4px 0,
    /* Top-right corner */ calc(100% - 4px) 0,
    calc(100% - 4px) 2px,
    calc(100% - 2px) 2px,
    calc(100% - 2px) 4px,
    100% 4px,
    /* Bottom-right corner */ 100% calc(100% - 4px),
    calc(100% - 2px) calc(100% - 4px),
    calc(100% - 2px) calc(100% - 2px),
    calc(100% - 4px) calc(100% - 2px),
    calc(100% - 4px) 100%,
    /* Bottom-left corner */ 4px 100%,
    4px calc(100% - 2px),
    2px calc(100% - 2px),
    2px calc(100% - 4px),
    0 calc(100% - 4px)
  );
}

main {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font: inherit;
}

:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 52px;
  --footer-height: 67px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.illustration-main-fill {
  fill: var(--color-primary-main);
}

.illustration-light-fill {
  fill: var(--color-border-main);
}

.illustration-background-fill {
  fill: var(--color-logo-background);
}

.illustration-background-warning-fill {
  fill: var(--color-warning-background);
}

.illustration-background-paper-fill {
  fill: var(--color-background-paper);
}

.illustration-secondary-light-fill {
  fill: var(--color-secondary-light);
}

.illustration-text-primary-fill {
  fill: var(--color-text-primary);
}

/* Note: a fallback `stroke` property must be on the svg to work */
.illustration-main-stroke {
  stroke: var(--color-primary-main);
}

.illustration-light-stroke {
  stroke: var(--color-border-main);
}

.illustration-very-light-stroke {
  stroke: var(--color-border-light);
}

.illustration-background-stroke {
  stroke: var(--color-logo-background);
}

@media (max-width: 599.95px) {
  .sticky {
    position: sticky;
    right: 0;
    background: var(--color-background-paper);
  }
}

body.beamerAnnouncementBarTopActive {
  padding-top: 0 !important;
}

#beamerLastPostTitle {
  left: 120px !important;
}
