.cardContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.cardContent :global .errorMessage {
  margin: 0;
}

.nestedDivider {
  margin: 0 calc(-1 * var(--space-3));
}

.form > :global(.MuiFormControl-root) {
  margin-bottom: 28px;
}
